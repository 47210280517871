<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline">Reportes</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >
      <v-container fluid>
        <v-row>
          <v-col class="align-end" cols="12" sm="6" md="6">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value="date"
              persistent
              scrollable
              max-width="300px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="Filtrar por fecha"
                  prepend-icon="fa-calendar-alt"
                  readonly
                  filled
                  rounded
                  hide-details
                  :disabled="loading"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                range
                :title-date-format="title"
                scrollable
                :max="today"
              >
                <v-row no-gutters style="flex-wrap: nowrap">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    rounded
                    outlined
                    color="primary"
                    @click="modal = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    class="flex-grow-1 flex-shrink-0 ml-2"
                    rounded
                    color="primary"
                    @click="
                      $refs.dialog.save(date);
                      updateDateFilter();
                    "
                  >
                    Filtrar
                  </v-btn>
                </v-row>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <!-- <download-excel
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
              :data="excelData"
              :name="name"
            >
              <img5
                style="width: 50px; cursor: pointer"
                :src="require('@/assets/excel.png')"
              />
              <span class="font-weight-bold" style="cursor: pointer"
                >Descargar .xlsx</span
              >
            </download-excel> -->
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="allAnswers"
              :items-per-page="5"
              sort-desc
              :sort-by="['createdAt']"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatDate }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="align-end ma-5">
      <v-btn
        :loading="loading"
        @click="$emit('cancel')"
        class="save-btn"
        color="buttons"
        >{{ $t("button.close") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import downloadExcel from "vue-json-excel";

export default {
  name: "form-results",
  props: ["selectedItem"],
  components: {
    downloadExcel,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: false,
      answers: [],
      date: [],
      modal: null,
      questions: [],
      allAnswers: [],
      answered: 0,
      excelData: [],
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(30, "days")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
    };
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, h:mm a");
    },
  },
  computed: {
    ...mapState(["user", "groupId"]),

    sortedArray: function (array) {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return array.sort(compare);
    },
    name() {
      return `${moment().format("DD/MM/YYYY")}_RESULTADOS_${
        this.selectedItem.name
      }`;
    },
    headers() {
      let date = {
        text: "Fecha",
        value: "createdAt",
        width: "250px",
      };

      let questionsHeaders = [];

      if (this.questions) {
        questionsHeaders = this.questions.map((q) => {
          return {
            text: q.title,
            value: q[".key"],
          };
        });
      }

      return [...questionsHeaders, date];
    },
  },

  methods: {
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
    async getData() {
      await this.$binding(
        "answers",
        db
          .collection("forms")
          .doc(this.selectedItem[".key"])
          .collection("answers")
          .where(
            "createdAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "createdAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          )
      );

      for (let i = 0; i < this.answers.length; i++) {
        const f = this.answers[i];

        let doc = {
          createdAt: f.createdAt,
        };

        let excelItem = {
          Fecha: moment(f.createdAt.toDate())
            .tz("America/Tegucigalpa")
            .format("DD/MM/YYYY"),
          Hora: moment(f.createdAt.toDate())
            .tz("America/Tegucigalpa")
            .format("H:mm:ss"),
        };

        for (let j = 0; j < f.answers.length; j++) {
          let ans = f.answers[j];

          //   console.log(ans);
          excelItem[ans.question] = ans.answer;
          doc[ans.questionId] = ans.answer;
        }

        this.allAnswers.push(doc);
        this.excelData.push(excelItem);
      }

      console.log(this.excelData);
    },
  },

  async mounted() {
    this.$binding(
      "questions",
      db
        .collection("forms")
        .doc(this.selectedItem[".key"])
        .collection("questions")
        .where("deleted", "==", false)
    );
    this.getData();
  },
};
</script>
<style scoped lang="scss">
.answered {
  //   border-radius: 50%;
  border: 1px solid rgb(0, 107, 12);
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;

  b {
    font-size: 50px;
    margin-top: 40px;
  }

  h3 {
  }
}
</style>