<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline"
        >{{ selectedItem ? "Editar formulario" : "Nuevo formulario" }}
      </span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <!-- <v-card-subtitle>Hola</v-card-subtitle> -->
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >

      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="form-header">
            <v-text-field
              rounded
              outlined
              :loading="loading"
              label="Ingrese el título del formulario"
              v-model="form.title"
              :rules="[rules.required]"
            ></v-text-field>

            <v-textarea
              v-model="form.description"
              outlined
              :loading="loading"
              rounded
              :rules="[rules.required]"
              label="Ingrese una breve descripción del formulario"
              rows="2"
            ></v-textarea>

            <v-text-field
              rounded
              :loading="loading"
              outlined
              label="Mensaje de finalización del formulario"
              v-model="form.completionMessage"
              :rules="[rules.required]"
              hint="Este mensaje se mostrará al usuario una vez complete el formulario, puede agregar indicaciones del siguiente paso a seguir. "
            ></v-text-field>
          </div>
          <div
            v-for="(question, id) in form.questions"
            :key="id"
            @click="selectedQuestion = id"
            :class="{
              'mb-10': true,
              'mt-10': true,
              question: true,
            }"
          >
            <div>
              <v-row>
                <v-col
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                  "
                  cols="12"
                  sm="9"
                  md="9"
                >
                  <span style="font-size: 25px" class="mr-4"
                    >{{ id + 1 }}.</span
                  >
                  <v-text-field
                    filled
                    :loading="loading"
                    label="Título de la pregunta"
                    :rules="[rules.required]"
                    v-model="question.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    filled
                    :rules="[rules.required]"
                    :items="questionTypes"
                    label="Tipo de pregunta"
                    item-text="title"
                    value="value"
                    v-model="question.type"
                  >
                    <template v-slot:selection="data">
                      <v-icon class="mr-3" size="x-large">
                        {{ data.item.icon }}
                      </v-icon>
                      {{ data.item.title }}
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-icon class="mr-3" size="x-large">
                          {{ data.item.icon }}
                        </v-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.title
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>

                <v-col v-if="selectedQuestion == id" cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="question.description"
                    rows="2"
                    class="ml-8"
                    filled
                    label="Descripción de la pregunta"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
            <!-- <transition name="fade"> -->
            <div
              v-if="
                ['radio', 'checkbox', 'select'].includes(question.type) &&
                selectedQuestion == id
              "
              class="form-body ml-5 pl-5 mt-5"
            >
              <span class="mt-10">Opciones</span> <br />
              <v-btn
                color="primary"
                class="mt-5"
                v-if="!question.options || !question.options.length"
                @click="addOption(id, 0)"
                >Agregar respuestas</v-btn
              >
              <v-row :key="index" v-for="(item, index) of question.options">
                <v-col cols="11" sm="11" md="11" class="center-content">
                  <v-text-field
                    filled
                    :rules="[rules.required]"
                    v-model="item.title"
                    :label="`Opción ${index + 1}`"
                  ></v-text-field>
                </v-col>
                <v-col
                  class="center-content"
                  style="justify-content: flex-end"
                  cols="1"
                  sm="1"
                  md="1"
                >
                  <v-btn @click="removeOption(id, index)" icon
                    ><v-icon>fas fa-trash</v-icon></v-btn
                  >
                  <v-btn @click="addOption(id, index)" icon
                    ><v-icon>fas fa-plus</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <v-row v-if="selectedQuestion == id">
              <v-col
                style="display: flex; align-items: flex-end"
                cols="6"
                sm="6"
                md="6"
              >
                <v-switch
                  hide-details
                  v-model="question.required"
                  label="Respuesta requerida"
                ></v-switch>
              </v-col>
              <v-col
                class="right-content"
                style="align-items: flex-end"
                cols="6"
                sm="6"
                md="6"
                ><p
                  @click="removeQuestion(id)"
                  class="remove-question pa-0 ma-0 font-weight-bold"
                  style="cursor: pointer; color: rgb(142, 2, 2)"
                >
                  Eliminar
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="right-content">
            <v-btn @click="addQuestion" color="primary">
              <v-icon class="mr-5">fas fa-plus</v-icon>
              Agregar pregunta
            </v-btn>
          </div>
          <!-- </v-row> -->
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions style="display: flex; justify-content: flex-end">
      <v-container
        ><v-row>
          <v-col cols="12" sm="6" md="6">
            <v-switch :loading="loading" v-model="form.active" label="Activo">
            </v-switch>
          </v-col>
          <v-col
            style="
              justify-content: flex-end;
              align-items: flex-end;
              display: flex;
            "
            cols="12"
            sm="6"
            md="6"
          >
            <v-btn
              @click="selectedItem ? update() : save()"
              :loading="loading"
              color="buttons"
              >{{ $t("button.save") }}</v-btn
            >
          </v-col>
        </v-row></v-container
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "add-form",
  props: ["selectedItem"],
  data() {
    return {
      panel: [],
      snackbarText: "",
      snackbar: false,
      loading: false,
      rules: {
        required: (value) => !!value || this.$tc("rules.required"),
      },
      selectedQuestion: 0,
      valid: false,
      questionTypes: [
        {
          title: "Opciones multiples",
          value: "checkbox",
          icon: "fa-check-square",
        },
        { title: "Opción única", value: "radio", icon: "fa-check-circle" },
        { title: "Respuesta corta", value: "text", icon: "fa-comments" },
        {
          title: "Resuesta númerica",
          value: "number",
          icon: "fa-sort-numeric-up",
        },
        { title: "Fecha", value: "date", icon: "fa-calendar" },
      ],
      deletedQuestions: [],
      form: {
        title: "",
        questions: [
          {
            title: "",
            type: "",
            id: this.getRandomInt(),
            options: [
              {
                title: "",
                value: 1,
              },
            ],
            required: true,
            position: null,
            description: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },
  methods: {
    async update() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Debe completar todos los campos requeridos";
        this.snackbar = true;

        return;
      }

      this.loading = true;

      let qRef = await db
        .collection("forms")
        .doc(this.selectedItem[".key"])
        .update({
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
          title: this.form.title,
          description: this.form.description,
          completionMessage: this.form.completionMessage,
          active: this.form.active,
        });

      for (let i = 0; i < this.form.questions.length; i++) {
        const question = this.form.questions[i];

        if (question.isNew) {
          await db
            .collection("forms")
            .doc(this.selectedItem[".key"])
            .collection("questions")
            .add({
              title: question.title,
              type: question.type,
              position: i,
              options: question.options,
              required: question.required,
              description: question.description,
              createdAt: new Date(),
              createdBy: this.user.uid,
              groupId: this.groupId,
              deleted: false,
            });
        } else {
          await db
            .collection("forms")
            .doc(this.selectedItem[".key"])
            .collection("questions")
            .doc(question.id)
            .update({
              title: question.title,
              type: question.type,
              position: i,
              options: question.options,
              required: question.required,
              description: question.description,
              modifiedAt: new Date(),
              modifiedBy: this.user.uid,
              active: true,
            });
        }
      }

      for (let k = 0; k < this.deletedQuestions.length; k++) {
        const question = this.deletedQuestions[k];
        // console.log(question);
        await db
          .collection("forms")
          .doc(this.selectedItem[".key"])
          .collection("questions")
          .doc(question.id)
          .update({ active: false, deleted: true, deletedBy: this.user.uid });
      }

      this.loading = false;
      this.$emit("success");
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.snackbarText = "Debe completar todos los campos requeridos";
        this.snackbar = true;

        return;
      }

      this.loading = true;

      let qRef = await db.collection("forms").add({
        createdAt: new Date(),
        createdBy: this.user.uid,
        groupId: this.groupId,
        title: this.form.title,
        description: this.form.description,
        completionMessage: this.form.completionMessage,
        active: this.form.active,
        deleted: false,
      });

      let formId = qRef.id;

      for (let i = 0; i < this.form.questions.length; i++) {
        const question = this.form.questions[i];
        await db.collection("forms").doc(formId).collection("questions").add({
          title: question.title,
          type: question.type,
          position: i,
          options: question.options,
          required: question.required,
          description: question.description,
          createdAt: new Date(),
          createdBy: this.user.uid,
          groupId: this.groupId,
          deleted: false,
          active: true,
        });
      }

      this.loading = false;
      this.$emit("success");
    },
    removeOption(questionIndex, answerIndex) {
      this.form.questions = this.form.questions.map((e, index) => {
        if (index == questionIndex) {
          e.options = e.options.filter((e, id) => id != answerIndex);
        }

        return e;
      });
    },

    addOption(questionId, answerIndex) {
      this.form.questions[questionId].options.splice(answerIndex + 1, 0, {
        title: "",
        value: this.getRandomInt(),
      });
    },

    removeQuestion(index) {
      this.deletedQuestions.push(
        this.form.questions.find((e, id) => id == index)
      );

      this.form.questions = this.form.questions.filter((e, id) => id != index);
    },

    getRandomInt() {
      let min = Math.ceil(100000);
      let max = Math.floor(100000000);

      return Math.floor(Math.random() * (max - min) + min);
    },

    addQuestion() {
      this.form.questions.push({
        title: "",
        type: "",
        id: this.getRandomInt(),
        isNew: true,
        options: [
          {
            title: "",
            value: this.getRandomInt(),
          },
        ],
        required: true,
        position: "",
        description: "",
      });
      this.selectedQuestion = this.form.questions.length - 1;
    },
  },
  async mounted() {
    if (this.selectedItem) {
      let questions = await db
        .collection("forms")
        .doc(this.selectedItem[".key"])
        .collection("questions")
        .where("deleted", "==", false)
        .orderBy("position", "asc")
        .get();

      this.selectedItem.questions = questions.docs.map((e) => {
        let info = e.data();
        info.id = e.id;
        return info;
      });
      this.form = Object.assign({}, this.selectedItem);
    }
  },
};
</script>

<style scoped>
.question {
  background-color: rgba(241, 241, 241, 0.588);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgb(160, 159, 159);
}

.fade-enter {
  opacity: 0;
}

.form-header {
  background-color: rgba(255, 255, 255, 0.164);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgb(160, 159, 159);
}

.remove-question {
  /* position: fixed;
  bottom: 0;
  right: 0; */
}
</style>